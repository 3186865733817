<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Account</strong>
            </div>
            <div class="text-muted">Edit your account information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="First Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your first name.</span>
                  </template>
                  <a-input placeholder="Your First Name" v-decorator="['firstName', { initialValue: userData.firstName,
                rules: [
                  { required: true, message: 'First Name is required. '},
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}, ]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Last Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your last name.</span>
                  </template>
                  <a-input placeholder="Your Last Name" v-decorator="['lastName', { initialValue: userData.lastName,
                rules: [
                  { required: true, message: 'Last Name is required.' },
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your country.</span>
                  </template>
                  <a-select
                    v-decorator="['country', { initialValue: (userData.country === '' ? [] : userData.country),
                  rules : [{required: true}] } ]"
                    placeholder="Please select a country"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="obj in info.countries" :key="obj.CC">{{ obj.Country }}</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your city.</span>
                  </template>
                  <a-input placeholder="City" v-decorator="['city', { initialValue: userData.city,
                   rules: [
                    { max: 20, message: 'Maximum 20 characters allowed.'}
                  ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your zip code.</span>
                  </template>
                  <a-input type="number" placeholder="Zip Code" v-decorator="['zipCode', { initialValue: userData.zipCode,
                 rules: [{transform: function(value) { return Number(value)}, type: 'number'}]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your address.</span>
                  </template>
                  <a-input placeholder="Address" v-decorator="['address', { initialValue: userData.address,
                   rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your phone number.</span>
                  </template>
                  <a-input @keydown="phoneRegExp($event)" placeholder="Phone" v-decorator="['phone', { initialValue: userData.phone,
                 rules: [
                   { message: 'Invalid phone number.' },
                   { max: 20, message: 'Maximum 20 characters allowed.'}
                   ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Skype">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Your Skype username.</span>
                  </template>
                  <a-input placeholder="Skype" v-decorator="['skype', { initialValue: userData.skype,
                   rules: [
                    { max: 32, message: 'Maximum 32 characters allowed.'}
                   ]}]"/>
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5  mx-2">Save Changes</button>
                </div>
              </div>
            </a-form>
            <br>
            <a-divider>Change Password</a-divider>
            <br>
            <a-form :form="form2" @submit.prevent="changePassword">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Current Password">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Enter your current password.</span>
                  </template>
                  <a-input placeholder="Current Password"
                           v-decorator="['OldPassword', {
                             initialValue: null,
                              rules: [
                                { required: true, message: 'Please enter your current password.' },
                                { max: 64, message: 'Maximum 64 characters allowed.'}
                              ]}]"
                           type="password"
                  />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="New Password">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Enter your new password.</span>
                  </template>
                  <a-input placeholder="New Password"
                           v-decorator="['Password', {
                             initialValue: null,
                              rules: [
                                 { required: true, message: 'Please enter your new password.' },
                                 { max: 64, message: 'Maximum 64 characters allowed.'},
                                 { validator: validateToNextPassword },
                              ]
                           }]"
                           type="password"
                  />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Confirm Password">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Enter your new password again.</span>
                  </template>
                  <a-input placeholder="Confirm Password"
                           v-decorator="['Confirm',
                           { initialValue: null,
                            rules: [
                              { required: true, message: 'Please confirm your password.' },
                              { max: 64, message: 'Maximum 64 characters allowed.'},
                              { validator: compareToFirstPassword },
                            ]
                           }]"
                           type="password"
                  />
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5  mx-2">Change Password</button>
                </div>
              </div>
              <a-divider></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="button" @click="goBack" class="btn btn-light px-5 ml-2">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import phoneRegExp from '@/services/utils'
import { updateUserAccount, updateUserPassword } from '@/api/user'

export default {
  components: {},
  computed: {
    ...mapState(['info', 'auth']),
    userData: function () {
      return this.auth
    },
  },
  data() {
    return {
      confirmDirty: false,
      form: this.$form.createForm(this, { name: 'editAccount' }),
      form2: this.$form.createForm(this, { name: 'editPassword' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    phoneRegExp(e) {
      if ((/^[0-9,+]$/.test(e.key) && /^\+?\d*$/.test(e.target.value + e.key)) || e.key === 'Backspace') {
        return true
      } else {
        e.preventDefault()
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form2
      if (value.length < 8) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Invalid password, minimum length is 8.')
      } else if (value && value !== form.getFieldValue('Password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Passwords mismatch.')
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form2
      if (value.length < 8) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Invalid password, minimum length is 8.')
      } else if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    changePassword() {
      this.form2.validateFields((err, values) => {
        if (!err) {
          updateUserPassword(values).then(response => {
            this.$notification.success({
              message: 'Password Updated.',
              description: `Password for user: ${response.User.FirstName} has been successfully updated.`,
            })
          })
          this.$store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
        } else if (err) {
          this.$notification.warning({
            message: 'Failed to change password.',
            // description: "Passwords don't match.",
          })
        }
        // TODO add notification for wrong old message
      })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          updateUserAccount(values).then(response => {
            this.$notification.success({
              message: 'User Updated.',
              description: `Account Updated. ${response.User.FirstName} has been successfully updated.`,
            })
            this.$store.state.auth.firstName = response.User.FirstName
            this.$store.state.auth.lastName = response.User.LastName
            this.$store.state.auth.phone = response.User.Phone
            this.$store.state.auth.email = response.User.Mail
            this.$store.state.auth.country = response.User.Country
            this.$store.state.auth.city = response.User.City
            this.$store.state.auth.address = response.User.Address
            this.$store.state.auth.skype = response.User.Skype
            this.$store.state.auth.zipCode = response.User.ZipCode
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('user/LOAD_USERS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
